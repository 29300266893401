import { getMyLSProp, setMyLSProps } from '../LocalStorageUtil'

const AI_VOICING = 'aiVoicing'
const ROOM_OWNER_VOICING = 'roomOwnerVoicing'
export function isAIDollsVoicingActive() {
  return !getMyLSProp(AI_VOICING)
}

export function AIDollsVoicingSettings(disable) {
  setMyLSProps(AI_VOICING, disable)
}

export function isRoomOwnerVoicingActive() {
  return false //!getMyLSProp(ROOM_OWNER_VOICING)
}

export function roomOwnerDollsVoicingSettings(disable) {
  setMyLSProps(ROOM_OWNER_VOICING, disable)
}
