import { GETRequest } from '../api/requestWrapper'
import { getCoreUrl } from '../api/apiURLUtil'
import { getState, setStoreWrapper } from '../StoreManager'

export function loadMyNewContentCounters(){
  GETRequest(getCoreUrl('/api/v1/subscriptions/updates-counts'), (data)=> {
    setStoreWrapper('auth.newFollowersCount', data.new_followers_no_match_count)
    setStoreWrapper('auth.newMatchCount', data.new_match_count)
    // console.log(data)
  })
}

export function reduceFansCount(){
  const count = getState('auth.newFollowersCount')
  if (count > 0) {
    setStoreWrapper('auth.newFollowersCount', count - 1)
  }
}
