import { GETRequest } from '../requestWrapper'
import { getCoreUrl } from '../apiURLUtil'
import { getMyUid } from '../../my'
import { TYPE_FANS, TYPE_I_LIKE, TYPE_MATCH, TYPE_SUBSCRIPTIONS } from '../../../modules/posts/signals/getPosts'
import { loadUsersAndProfiles } from '../../userLoader'
import { getState, setStoreWrapper } from '../../StoreManager'

const pages = {}
const loadingProgress = {}
const ITEMS_ON_PAGE = 50
const cash = {}

export const ALL_LOADED = 'all_loaded'
export const LOADING = 'loading'

export const LOADED = 'loaded'

export function getMatches() {
  getDatingOnFeed(TYPE_MATCH)
}

export function getFansFeed() {
  getDatingOnFeed(TYPE_FANS)
}

export function getILikeFeed() {
  getDatingOnFeed(TYPE_I_LIKE)
}

export function getDatingOnFeed(feed) {
  if (checkLoading(feed)) {
    if (checkLoading(feed, ALL_LOADED)) {
      setStoreWrapper('posts.topPosts', cash[getMyType(feed)])
      setStoreWrapper('posts.loadingPostsStatus', LOADED)
      return
    }
    return
  }
  setLoading(feed, LOADING)
  let url
  if (feed === TYPE_MATCH) {
    url = '/api/v1/subscriptions/match?page=' + getPage(feed)
  } else if (feed === TYPE_FANS) {
    //фанатов мы всех отсматриваем всех подряд, страницы не нужны
    url = '/api/v1/followers/no-match'
  } else if (feed === TYPE_I_LIKE) {
    url = '/api/v1/subscriptions/no-match?page=' + getPage(feed)
  }
  GETRequest(getCoreUrl(url), data => {
    let uids
    if (feed === TYPE_MATCH || feed === TYPE_I_LIKE){
      uids = data.subscriptions
    } else if (feed === TYPE_FANS) {
      uids = data.followers
    }
    if (!uids || !uids.length) {
      setStoreWrapper('posts.loadingPostsStatus', ALL_LOADED)
      setStoreWrapper('posts.topPosts', getState('topPosts', []))
      setLoading(feed, ALL_LOADED)
      return
    }

    const status = uids.length < ITEMS_ON_PAGE ? ALL_LOADED : false
    setLoading(feed, status)

    //запросим юзеров с профилями
    loadUsersAndProfiles(uids, () => {
      const topPosts = getState('posts.topPosts', []).concat(uids)
      cash[getMyType(feed)] = topPosts
      setStoreWrapper('posts.topPosts', topPosts)
      setStoreWrapper('posts.loadingPostsStatus', uids.length < ITEMS_ON_PAGE ? ALL_LOADED : LOADED)
    })
  }, ()=>{
    setStoreWrapper('posts.loadingPostsStatus', LOADED)
    setStoreWrapper('posts.topPosts', getState('topPosts', []))
  })
  updateCountPage(feed)
}

export function clearDatingCash(feed) {
  delete cash[getMyType(feed)]
  cleanCountPage(feed)
  setLoading(feed, false)
}

function getMyType(feed) {
  return feed + getMyUid()
}

function checkLoading(feed, loading) {
  if (loading) {
    return loadingProgress[getMyType(feed)] === loading
  }
  return loadingProgress[getMyType(feed)]
}

function setLoading(feed, loading) {
  loadingProgress[getMyType(feed)] = loading
}

function getPage(feed) {
  return pages[getMyType(feed)] || 0
}

function updateCountPage(feed) {
  pages[getMyType(feed)] = getPage(feed) + 1
}

function cleanCountPage(feed) {
  delete pages[getMyType(feed)]
}

