import { state } from 'cerebral'
import config from '@/config'
import { getSessionId } from '@/utils'
import { savePostsData } from '../../posts/signals/onGetPosts'
import { BAD_TITLE, DESCRIPTION_REMOVED, PROFILE_TAG } from '../../../utils/postUtils'

const { ftCoreUrl } = config

export const getMoreContentModeration = async ({ store, get, props }) => {
  const filter = props.filter || get(state`moderation.typeModerFilter`) || 'potentiallyInteresting'

  if ((get(state`moderation.isLoading`) !== undefined && get(state`moderation.isLoading`) !== false) ||
    (get(state`moderation.allLoaded`) !== undefined && get(state`moderation.allLoaded`) !== false)) {
    return
  }

  store.set('moderation.isLoading', true)

  let postsReports = null
  const sid = getSessionId()
  let queryString = null
  let getParams = `?sid=${sid}`
  getParams += `&lang=${get(state`intl.lang`)}`

  let moderPostsList = []
  let page = 0
  if (!props.clickFilter) {
    moderPostsList = get(state`moderation.data`)
    page = get(state`moderation.page`)
  }

  const getPage = page === 0 ? '' : `&page=${page}`

  switch (filter) {
    case 'potentiallyInteresting': // Потенциально интересное
      getParams += '&moderationStatus=onlyOnModeration&withNotInteresting=1&isNotAdult=true'
      getParams += '&potentiallyInteresting=1'
      break
    case 'complaints': // Жалобы
      getParams += '&ageRating=12,14,16,18&withNotInteresting=1&withOnModeration=1'
      postsReports = `${ftCoreUrl}/api/v1/posts/reports${getParams}${getPage}`
      break
    case 'posts': // Посты
      getParams += '&moderationStatus=onlyOnModeration&withNotInteresting=1&isNotAdult=true'
      getParams += '&excludeTags=1067952,1131021'
      break
    case 'ankets': // Анкеты
      getParams += '&moderationStatus=onlyOnModeration&withNotInteresting=1&isNotAdult=true'
      getParams += '&tags=1067952'
      break
    case 'theme': // Темы
      getParams += '&moderationStatus=onlyOnModeration&withNotInteresting=1&isNotAdult=true'
      getParams += '&tags=1131021'
      break
    case 'onlymodels': // Авторы
      getParams += '&moderationStatus=onlyOnModeration&withNotInteresting=1&isNotAdult=true'
      getParams += '&onlyModels=true'
      getParams += '&tags=1067952'
      break
    case 'interesting': // Интересные
      getParams += '&tags=36'
      getParams += '&ageRating=12,14,16,18'
      break
    case 'rejected': // Откланенные
      getParams += '&moderationStatus=onlyNotApproved&withNotInteresting=1'
      break
    case 'vipOnly': // только вип
      getParams += '&moderationStatus=onlyOnModeration&withNotInteresting=1&isNotAdult=true&onlyVip=true'
      break
    case 'video': // только видео
      getParams += '&moderationStatus=onlyOnModeration&withNotInteresting=1&isNotAdult=true&postType=1'
      break
    case '12': //
      getParams += '&ageRating=12'
      break
    case '14': //
      getParams += '&ageRating=14'
      break
    case 'badHead': //
      getParams += '&moderationStatus=onlyOnModeration&withNotInteresting=1&tags=' + BAD_TITLE
      break
    case 'badText': //
      getParams += '&moderationStatus=onlyOnModeration&withNotInteresting=1&tags=' + DESCRIPTION_REMOVED
      break
    case 'adult': //
      getParams += '&isAdult=true&moderationStatus=onlyOnModeration&withNotInteresting=1'
      break
    case 'adultPF': //
      getParams += '&isAdult=true&moderationStatus=onlyOnModeration&withNotInteresting=1'
      getParams += '&tags=' + PROFILE_TAG
      break
    default:
      getParams += '&moderationStatus=onlyOnModeration&withNotInteresting=1&isNotAdult=true'
      getParams += '&potentiallyInteresting=1'
      break
  }

  if (!postsReports) {
    queryString = `${ftCoreUrl}/api/v1/posts/moderation${getParams}${getPage}`
  } else {
    queryString = postsReports
  }

  try {
    let response = null
    if (queryString) {
      response = await fetch(queryString)
      if (!response.ok) {
        return
      }

      const result = await response.json()
      store.set('moderation.isLoading', false)
      if (Object.keys(result.posts).length !== 0) {
        moderPostsList = moderPostsList.concat(Object.values(result.posts))
        savePostsData({ store, get, props: { posts: moderPostsList } })
        store.set('moderation.data', moderPostsList)
        page++
        store.set('moderation.page', page)
        store.set('moderation.allLoaded', Object.keys(result.posts).length === 0)
      }
    } else {
      console.warn('@@@ queryString is null')
    }
  } catch (e) {
    console.warn('@@@ load moderations error:', e)
  }
  store.set('moderation.typeModerFilter', filter)
  return
}
