import { isFemale } from '../user/userInfos'
import { POSTRequest } from '../api/requestWrapper'
import config from '@/config'
import { isCyber } from '../cyber/cyberUtil'
import { isMe } from '../checks'
import { isAIDollsVoicingActive, isRoomOwnerVoicingActive } from './voicingSerrings'
import is from 'is_js'
import { imInRoom } from '../appUtil'

const { postUploadUrl } = config

const MALE_ONYX = 'onyx'
const FEMALE_NOVA = 'nova'
const FEMALE_SHIMMER = 'shimmer'

export function textToSpeech(message, voice, cb) {
  if (!message || !message.length) {
    if (cb) {
      cb()
    }
    return
  }
  POSTRequest(postUploadUrl + '/api/v1/message-to-speech', { message, voice }, (params) => {
    console.log('voice')
    if (params.result) {
      playSound(params.result)
      if (cb) {
        cb()
      }
    }
  }, () => {
    console.log('ERROR /api/v1/message-to-speech ')
    if (cb) {
      cb()
    }
  })
}

export function playUserVoice(message, uid, cb) {
  if (isMe(uid) || is.safari()) {
    if (cb) {
      cb()
    }
    return
  }
  //тут возможно потом добавится условие на VIP
  if (isCyber(uid)) {
    if (isAIDollsVoicingActive()) {
      textToSpeech(message, getVoice(uid), cb)
    } else {
      if (cb) {
        cb()
      }
    }
    return
  }
  if (imInRoom(uid) && isRoomOwnerVoicingActive()) {
    textToSpeech(message, getVoice(uid), cb)
    return
  }

  if (cb) {
    cb()
  }
}

async function playSound(soundUrl) {
  try {
    const s = new Audio(soundUrl)
    await s.play()
  } catch (e) {
    console.log('sound not allowed')
  }
}

const SOPHIA = '84b3ae7e36ae4858317d4852c8e1148f'

function getVoice(uid) {
  if (uid === SOPHIA) {
    return FEMALE_SHIMMER
  }
  return isFemale(uid) ? FEMALE_NOVA : MALE_ONYX
}
