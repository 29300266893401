import { sequences, state } from 'cerebral'
import { checkMySubscription, getSubTypeColorCustom, isSubscribeAvailable } from '../../utils/subscriptionUtil'
import { array, bool, func, number, object, string } from 'prop-types'
import { connect } from '@cerebral/react'
import React, { Component } from 'react'
import { isMobileSkin } from '../../utils'
import { subscriptionButtonClick } from '../../utils/subscriptionButtonClick'
import './buttons.css'
import './likeAnimation.css'
import { getState, setStoreWrapper } from '../../utils/StoreManager'
import is from 'is_js'
import { cancelDislike, dislikePost, dislikeUser } from '../../utils/post/dislikePost'
import { myAnimateToggle } from '../Levels/animatedLike'
import AnimatedField from '../Levels/AnimatedField'
import { likeButtonClass } from '../../utils/buttonStyleUtil'
import { scrollToNextPost } from '../../utils/navigation/datingScrollUtil'
import { isUID } from '../../utils/isUID'

export function getLikeID(id) {
  return 'like' + id
}

class SubscriptionButton extends Component {
  state = {
    click: false
  }

  animation(toggle) {
    if (this.props.flyoutAnimation) {
      myAnimateToggle(toggle)
    }
  }

  render() {
    const {
      uid,
      intl,
      compactEnable,
      alwaysGray,
      postId,
      style,
      iconMode,
      posterMode,
      onGray,
      dislikeMod,
      barButton,
      myLikes,
      forDislikeCancel,
      flyoutAnimation
    } = this.props

    const subType = (postId && !forDislikeCancel) ? myLikes.indexOf(postId) === -1 : checkMySubscription(uid)

    let buttonClass
    if (iconMode) {
      // buttonClass = 'buttonIcon'
    } else {
      if (onGray) {
        buttonClass = (subType || alwaysGray) ? 'simpleButton buttonOnGray' : 'simpleButton buttonBlue'
      } else {
        buttonClass = (subType || alwaysGray) ? 'simpleButton' : 'simpleButton buttonBlue'
      }
    }

    if (barButton) {
      buttonClass += ' mobileBar'
    }
    if (posterMode) {
      buttonClass = likeButtonClass(subType)
    }
    let iconClass = subType ? 'icon-flirty-like-full buttonIcon' : 'icon-flirty-like buttonIcon'

    //posterMode &&
    if (subType) {
      iconClass += ' filledLikeIcon'
    }
    let iconStyle
    let animate = false
    if (!posterMode) {
      iconStyle = { color: (!subType && alwaysGray) ? '#212121' : getSubTypeColorCustom(subType, iconMode ? '#000000' : '#FFF') }
    } else {
      animate = getState(getLikeID(uid)) && this.state.click

      if (this.prevAnimate !== animate && animate) {
        setTimeout(() => {
          this.setState({ click: false })
          setStoreWrapper(getLikeID(uid), false)
        }, 800)
      }

      this.prevAnimate = animate
    }

    return (
      <button className={buttonClass} id={flyoutAnimation ? 'flyoutAnimationButton' : ('subBtn' + uid)} style={style}
              onMouseDown={() => {
                this.animation(true)
              }}
              onTouchStart={() => {
                this.animation(true)
              }}
              onTouchEnd={() => {
                this.animation(false)
              }}
              onMouseUp={() => {
                this.animation(false)
              }}
              onMouseOut={() => {
                this.animation(false)
              }}
              onClick={() => {
                // //если у меня нет профиля то лайкать нельзя
                // if (posterMode && !getProfilePostId(getMyUid()) && !imVIP()) {
                //   return datingNeedProfile()
                // }
                if (subType && dislikeMod) {
                  if (postId) {
                    if (isUID(postId)) {
                      dislikeUser(postId)
                    }
                  }
                  if (uid && !isUID(postId)) {
                    dislikeUser(uid)
                  }
                  return
                }
                if (!isSubscribeAvailable(true)) {
                  return
                }

                this.setState({ click: true })
                subscriptionButtonClick(uid, flyoutAnimation)
                cancelDislike(postId)
                if (this.props.autoScroll && !subType) {
                  scrollToNextPost(postId)
                }
              }}>
        {!animate &&
          <i className={iconClass}
             style={iconStyle}/>
        }
        {(barButton || (!(isMobileSkin() && compactEnable) && !iconMode)) &&
          <span>{subType ? intl.subscribed : intl.subscribe}</span>
        }
        {animate &&
          <div className="like like-animation" style={{ marginTop: is.ios() ? '-2px' : '0px' }} id={getLikeID(uid)}/>
        }
        {
          flyoutAnimation && <AnimatedField/>
        }
      </button>
    )
  }
}

SubscriptionButton.propTypes = {
  alwaysGray: bool,
  autoScroll: bool,
  barButton: bool,
  compactEnable: bool,
  createPanel: func,
  dislikeMod: bool,
  flyoutAnimation: bool,
  forDislikeCancel: bool,
  iconMode: bool,
  intl: object,
  isLoggedIn: bool,
  likeClick: bool,
  myLikes: array,
  onGray: bool,
  postId: number,
  posterMode: bool,
  style: object,
  subs: object,
  uid: string
}
export default connect({
    intl: state`intl.content`,
    subs: state`auth.subs`,
    createPanel: sequences`app.createPanel`,
    isLoggedIn: state`auth.isLoggedIn`,
    myLikes: state`posts.myLikes`,
  },
  SubscriptionButton
)
